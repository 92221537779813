body {
  background-color: #121212 !important;
  color: #e0e0e0 !important;
  width: 100%;
}

html, body {
  height: 100%;
  margin: 0;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-wrapper > * {
  flex-shrink: 0;
}

.content {
  flex-grow: 1;
}

.navbar, .footer {
  background-color: #1f1f1f;
}

.navbar-brand, .nav-link {
  color: #e0e0e0 !important;
}

.section-title {
  color: #ff5722;
}

.btn-custom {
  background-color: #ff5722;
  border: none;
  color: white;
}

.btn-custom:hover {
  background-color: #ff784e;
}

.my-name{
  color: orange;
}

.experience-title{
  font-weight: bold;
}

.clickable{
  cursor: pointer;
}

.nohyperlink{
  color: inherit;
  text-decoration: none;
}

.empty-div-separador{
  width: 10px;
  height: 1px;
}

.card-box{
  max-width: 100vw;
}

.special-link{
  color: orange;
  text-decoration: none;
}

.clickable{
  transition: color 0.5s;
}

.clickable:hover{
  color: #ff784e;
}


.car-card{
  height: 200px;
  border: 1px solid orange;
}

.list-style{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.list-style-item{
  float: left;
  padding: 10px;
}

.list-style-item-inner{
  text-decoration: none;
  transition: color 0.5s;
}

.list-style-item:hover{
  color: #ff5722 !important;
}

.image-gallery {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.image-item {
  flex: 1 1 calc(33.33% - 10px);
  max-width: 33.33%;
}

.image-item-double{
  flex: 1 1 calc(50% - 10px);
  max-width: 50%;
}

.image-item-single{
  flex: 1 1 calc(100% - 10px);
  max-width: 100%;
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-item-vertical {
  flex: 1 1 calc(33.33% - 10px);
  max-width: 33.33%;
  height: 600px;
}

.custom-input {
  background-color: #f0f0f0 !important;
  width: 80% !important;
  margin: 0 auto !important;
  display: block !important;
  border: 1px solid #ccc !important;
  padding: 10px !important;
  border-radius: 5px !important;
}

.custom-input:focus {
  outline: none !important;
  border-color: #007bff !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 20px; /* Space between items */
}

.grid-item {
  text-align: center;
  background-color: #121212;
  padding: 15px;
}

.grid-image {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  margin-bottom: 10px;
}

.random-generators {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.generator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-field {
  width: 150px;
  padding: 8px;
  margin: 5px;
  font-size: 16px;
}

.generate-btn {
  background-color: orange;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.generate-btn:hover {
  background-color: darkorange;
}

p {
  font-size: 16px;
  font-weight: bold;
}


@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}






